.app-w-50-px {
  width: 50px;
  min-width: 50px;
}

.app-w-100-px {
  width: 100px;
  min-width: 100px;
}

.app-w-150-px {
  width: 150px;
  min-width: 150px;
}

.app-w-200-px {
  width: 200px;
  min-width: 200px;
}

.app-w-250-px {
  width: 250px;
  min-width: 250px;
}

.app-w-300-px {
  width: 300px;
  min-width: 300px;
}

.app-w-350-px {
  width: 350px;
  min-width: 350px;
}

.app-w-400-px {
  width: 400px;
  min-width: 400px;
}

.app-w-450-px {
  width: 450px;
  min-width: 450px;
}

.app-w-500-px {
  width: 500px;
  min-width: 500px;
}