.app-primary-border-top {
  border-top-color: $app-primary-color;
  border-top-style: solid;
  border-top-width: thin;
}

.app-secondary-border-top {
  border-top-color: $app-secondary-color;
  border-top-style: solid;
  border-top-width: thin;
}

.app-tertiary-border-top {
  border-top-color: $app-tertiary-color;
  border-top-style: solid;
  border-top-width: thin;
}

.app-quaternary-border-top {
  border-top-color: $app-quaternary-color;
  border-top-style: solid;
  border-top-width: thin;
}

.app-quinary-border-top {
  border-top-color: $app-quinary-color;
  border-top-style: solid;
  border-top-width: thin;
}

.app-accent-border-top {
  border-top-color: $app-accent-color;
  border-top-style: solid;
  border-top-width: thin;
}

.app-neutral-white-border-top {
  border-top-color: $app-neutral-color-white;
  border-top-style: solid;
  border-top-width: thin;
}

.app-neutral-black-border-top {
  border-top-color: $app-neutral-color-black;
  border-top-style: solid;
  border-top-width: thin;
}

.app-neutral-grey-1-border-top {
  border-top-color: $app-neutral-color-grey-1;
  border-top-style: solid;
  border-top-width: thin;
}

.app-warning-border-top {
  border-top-color: $app-warning-color;
  border-top-style: solid;
  border-top-width: thin;
}

.app-danger-border-top {
  border-top-color: $app-danger-color;
  border-top-style: solid;
  border-top-width: thin;
}

.app-primary-border-bottom {
  border-bottom-color: $app-primary-color;
  border-bottom-style: solid;
  border-bottom-width: thin;
}

.app-secondary-border-bottom {
  border-bottom-color: $app-secondary-color;
  border-bottom-style: solid;
  border-bottom-width: thin;
}

.app-tertiary-border-bottom {
  border-bottom-color: $app-tertiary-color;
  border-bottom-style: solid;
  border-bottom-width: thin;
}

.app-quaternary-border-bottom {
  border-bottom-color: $app-quaternary-color;
  border-bottom-style: solid;
  border-bottom-width: thin;
}

.app-quinary-border-bottom {
  border-bottom-color: $app-quinary-color;
  border-bottom-style: solid;
  border-bottom-width: thin;
}

.app-accent-border-bottom {
  border-bottom-color: $app-accent-color;
  border-bottom-style: solid;
  border-bottom-width: thin;
}

.app-neutral-white-border-bottom {
  border-bottom-color: $app-neutral-color-white;
  border-bottom-style: solid;
  border-bottom-width: thin;
}

.app-neutral-black-border-bottom {
  border-bottom-color: $app-neutral-color-black;
  border-bottom-style: solid;
  border-bottom-width: thin;
}

.app-neutral-grey-1-border-bottom {
  border-bottom-color: $app-neutral-color-grey-1;
  border-bottom-style: solid;
  border-bottom-width: thin;
}

.app-warning-border-bottom {
  border-bottom-color: $app-warning-color;
  border-bottom-style: solid;
  border-bottom-width: thin;
}

.app-danger-border-bottom {
  border-bottom-color: $app-danger-color;
  border-bottom-style: solid;
  border-bottom-width: thin;
}