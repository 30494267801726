.app-sign-up-view {

    .app-sign-up-view-phone-input {

        input {
            border-radius: 0.7rem;
            border: 1px solid #ced4da;
            padding: 6px
        }

    }

}