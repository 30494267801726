.app-primary-bg {
  background-color: $app-primary-color;
}

.app-secondary-bg {
  background-color: $app-secondary-color;
}

.app-tertiary-bg {
  background-color: $app-tertiary-color;
}

.app-quaternary-bg {
  background-color: $app-quaternary-color;
}

.app-quinary-bg {
  background-color: $app-quinary-color;
}

.app-accent-bg {
  background-color: $app-accent-color;
}

.app-neutral-white-bg {
  background-color: $app-neutral-color-white;
}

.app-neutral-black-bg {
  background-color: $app-neutral-color-black;
}

.app-neutral-grey-1-bg {
  background-color: $app-neutral-color-grey-1;
}

.app-warning-bg {
  background-color: $app-warning-color;
}

.app-danger-bg {
  background-color: $app-danger-color;
}