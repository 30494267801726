.app-primary-text {
  color: $app-primary-color;
}

.app-secondary-text {
  color: $app-secondary-color;
}

.app-tertiary-text {
  color: $app-tertiary-color;
}

.app-quaternary-text {
  color: $app-quaternary-color;
}

.app-quinary-text {
  color: $app-quinary-color;
}

.app-accent-text {
  color: $app-accent-color;
}

.app-neutral-white-text {
  color: $app-neutral-color-white;
}

.app-neutral-black-text {
  color: $app-neutral-color-black;
}

.app-neutral-grey-1-text {
  color: $app-neutral-color-grey-1;
}

.app-warning-text {
  color: $app-warning-color;
}

.app-danger-text {
  color: $app-danger-color;
}