@import "primereact/resources/themes/lara-light-amber/theme.css"; //theme
@import "primereact/resources/primereact.min.css"; //core css
@import "primeicons/primeicons.css"; //icons

.p-column-filter-menu {
  margin-left: unset !important;
}

.p-stepper-action {
  background-color: transparent;
}

.p-stepper-panels {
  background-color: transparent;
}

.p-stepper {

  &.p-stepper-vertical {

    .p-stepper-toggleable-content {      
      background-color: transparent;
    }

  }

}

.p-stepper {
  
  .p-stepper-header {
    
    &.p-highlight {
      
      .p-stepper-number {
        background-color: $app-accent-color;                
      }
      
    }
    
  }
  
}