.app-anton-regular-font {
  font-family: $anton-regular;
}

.app-pharma-regular-font {
  font-family: $pharma-regular;
}

.app-pharma-bold-font {
  font-family: $pharma-bold;
}

.app-suomi-hand-script-font {
  font-family: $suomi-hand-script;
}

.app-montserrat-thin-font {
  font-family: $montserrat-thin;
}

.app-montserrat-thin-italic-font {
  font-family: $montserrat-thin-italic;
}

.app-montserrat-light-font {
  font-family: $montserrat-light;
}

.app-montserrat-light-italic-font {
  font-family: $montserrat-light-italic;
}

.app-montserrat-extra-light-font {
  font-family: $montserrat-extra-light;
}

.app-montserrat-extra-light-italic-font {
  font-family: $montserrat-extra-light-italic;
}

.app-montserrat-regular-font {
  font-family: $montserrat-regular;
}

.app-montserrat-regular-italic-font {
  font-family: $montserrat-regular-italic;
}

.app-montserrat-medium-font {
  font-family: $montserrat-medium;
}

.app-montserrat-medium-italic-font {
  font-family: $montserrat-medium-italic;
}

.app-montserrat-semi-bold-font {
  font-family: $montserrat-semi-bold;
}

.app-montserrat-semi-boldI-ialic-font {
  font-family: $montserrat-semi-boldI-ialic;
}

.app-montserrat-bold-font {
  font-family: $montserrat-bold;
}

.app-montserrat-bold-italic-font {
  font-family: $montserrat-bold-italic;
}

.app-montserrat-extra-bold-font {
  font-family: $montserrat-extra-bold;
}

.app-montserrat-extra-bold-italic-font {
  font-family: $montserrat-extra-bold-italic;
}

.app-montserrat-black-font {
  font-family: $montserrat-black;
}

.app-montserrat-black-italic-font {
  font-family: $montserrat-black-italic;
}