@font-face {
    font-family: 'anton-regular';
    src: url('../../../assets/fonts/Anton/Anton-Regular.ttf') format("truetype");
}

@font-face {
    font-family: 'pharma-regular';
    src: url('../../../assets/fonts/Pharma/Pharma-Regular.ttf') format("truetype");
}

@font-face {
    font-family: 'pharma-bold';
    src: url('../../../assets/fonts/Pharma/Pharma-Bold.ttf') format("truetype");
}

@font-face {
    font-family: 'suomi-hand-script';
    src: url('../../../assets/fonts/Suomi-Hand-Script/Suomi-Hand-Script.ttf') format("truetype");
}

@font-face {
    font-family: 'montserrat-thin';
    src: url('../../../assets/fonts/Montserrat/Montserrat-Thin.otf') format("opentype");
}

@font-face {
    font-family: 'montserrat-thin-italic';
    src: url('../../../assets/fonts/Montserrat/Montserrat-ThinItalic.otf') format("opentype");
}

@font-face {
    font-family: 'montserrat-light';
    src: url('../../../assets/fonts/Montserrat/Montserrat-Light.otf') format("opentype");
}

@font-face {
    font-family: 'montserrat-light-italic';
    src: url('../../../assets/fonts/Montserrat/Montserrat-LightItalic.otf') format("opentype");
}

@font-face {
    font-family: 'montserrat-extra-light';
    src: url('../../../assets/fonts/Montserrat/Montserrat-ExtraLight.otf') format("opentype");
}

@font-face {
    font-family: 'montserrat-extra-light-italic';
    src: url('../../../assets/fonts/Montserrat/Montserrat-ExtraLightItalic.otf') format("opentype");
}

@font-face {
    font-family: 'montserrat-regular';
    src: url('../../../assets/fonts/Montserrat/Montserrat-Regular.otf') format("opentype");
}

@font-face {
    font-family: 'montserrat-regular-italic';
    src: url('../../../assets/fonts/Montserrat/Montserrat-RegularItalic.otf') format("opentype");
}

@font-face {
    font-family: 'montserrat-medium';
    src: url('../../../assets/fonts/Montserrat/Montserrat-Medium.otf') format("opentype");
}

@font-face {
    font-family: 'montserrat-medium-italic';
    src: url('../../../assets/fonts/Montserrat/Montserrat-MediumItalic.otf') format("opentype");
}

@font-face {
    font-family: 'montserrat-semi-bold';
    src: url('../../../assets/fonts/Montserrat/Montserrat-SemiBold.otf') format("opentype");
}

@font-face {
    font-family: 'montserrat-semi-boldI-ialic';
    src: url('../../../assets/fonts/Montserrat/Montserrat-SemiBoldItalic.otf') format("opentype");
}

@font-face {
    font-family: 'montserrat-bold';
    src: url('../../../assets/fonts/Montserrat/Montserrat-Bold.ttf') format("truetype");
}

@font-face {
    font-family: 'montserrat-bold-italic';
    src: url('../../../assets/fonts/Montserrat/Montserrat-BoldItalic.otf') format("opentype");
}

@font-face {
    font-family: 'montserrat-extra-bold';
    src: url('../../../assets/fonts/Montserrat/Montserrat-ExtraBold.otf') format("opentype");
}

@font-face {
    font-family: 'montserrat-extra-bold-italic';
    src: url('../../../assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.otf') format("opentype");
}

@font-face {
    font-family: 'montserrat-black';
    src: url('../../../assets/fonts/Montserrat/Montserrat-Black.otf') format("opentype");
}

@font-face {
    font-family: 'montserrat-black-italic';
    src: url('../../../assets/fonts/Montserrat/Montserrat-BlackItalic.otf') format("opentype");
}

$anton-regular: (
    'anton-regular',
    'sans-serif'
);

$pharma-regular: (
    'pharma-regular',
    'sans-serif'
);

$pharma-bold: (
    'pharma-bold',
    'sans-serif'
);

$suomi-hand-script: (
    'suomi-hand-script',
    'sans-serif'
);

$montserrat-thin: (
    'montserrat-thin',
    'sans-serif'
);

$montserrat-thin-italic: (
    'montserrat-thin-italic',
    'sans-serif'
);

$montserrat-light: (
    'montserrat-light',
    'sans-serif'
);

$montserrat-light-italic: (
    'montserrat-light-italic',
    'sans-serif'
);

$montserrat-extra-light: (
    'montserrat-extra-light',
    'sans-serif'
);

$montserrat-extra-light-italic: (
    'montserrat-extra-light-italic',
    'sans-serif'
);

$montserrat-regular: (
    'montserrat-regular',
    'sans-serif'
);

$montserrat-regular-italic: (
    'montserrat-regular-italic',
    'sans-serif'
);

$montserrat-medium: (
    'montserrat-medium',
    'sans-serif'
);

$montserrat-medium-italic: (
    'montserrat-medium-italic',
    'sans-serif'
);

$montserrat-semi-bold: (
    'montserrat-semi-bold',
    'sans-serif'
);

$montserrat-semi-boldI-ialic: (
    'montserrat-semi-boldI-ialic',
    'sans-serif'
);

$montserrat-bold: (
    'montserrat-bold',
    'sans-serif'
);

$montserrat-bold-italic: (
    'montserrat-bold-italic',
    'sans-serif'
);

$montserrat-extra-bold: (
    'montserrat-extra-bold',
    'sans-serif'
);

$montserrat-extra-bold-italic: (
    'montserrat-extra-bold-italic',
    'sans-serif'
);

$montserrat-black: (
    'montserrat-black',
    'sans-serif'
);

$montserrat-black-italic: (
    'montserrat-black-italic',
    'sans-serif'
);

