.app-primary-outline-button {
  border-color: $app-primary-color;
  color: $app-primary-color;
  
  &:hover {
    background-color: $app-primary-color  !important;
    border-color: $app-primary-color  !important;
    color: $app-neutral-color-black  !important;
  }
}

.app-secondary-outline-button {
  border-color: $app-secondary-color;
  color: $app-secondary-color;
  
  &:hover {
    background-color: $app-secondary-color  !important;
    border-color: $app-secondary-color  !important;
    color: $app-neutral-color-white  !important;
  }
}

.app-tertiary-outline-button {
  border-color: $app-tertiary-color;
  color: $app-tertiary-color;
  
  &:hover {
    background-color: $app-tertiary-color  !important;
    border-color: $app-tertiary-color  !important;
    color: $app-neutral-color-black  !important;
  }
}

.app-quaternary-outline-button {
  border-color: $app-quaternary-color;
  color: $app-quaternary-color;

  &:hover {
    background-color: $app-quaternary-color !important;
    border-color: $app-quaternary-color !important;
    color: $app-neutral-color-black !important;
  }
}

.app-quinary-outline-button {
  border-color: $app-quinary-color;
  color: $app-quinary-color;

  &:hover {
    background-color: $app-quinary-color !important;
    border-color: $app-quinary-color !important;
    color: $app-neutral-color-black !important;
  }
}

.app-accent-outline-button {
  border-color: $app-accent-color;
  color: $app-accent-color;
  
  &:hover {
    background-color: $app-accent-color  !important;
    border-color: $app-accent-color  !important;
    color: $app-neutral-color-white  !important;
  }
}

.app-neutral-white-outline-button {
  border-color: $app-neutral-color-white;
  color: $app-neutral-color-white;
  
  &:hover {
    background-color: $app-neutral-color-white  !important;
    border-color: $app-neutral-color-white  !important;
    color: $app-neutral-color-black  !important;
  }
}

.app-neutral-black-outline-button {
  border-color: $app-neutral-color-black;
  color: $app-neutral-color-black;
  
  &:hover {
    background-color: $app-neutral-color-black  !important;
    border-color: $app-neutral-color-black  !important;
    color: $app-neutral-color-white  !important;
  }
}

.app-neutral-grey-1-outline-button {
  border-color: $app-neutral-color-grey-1;
  color: $app-neutral-color-grey-1;
  
  &:hover {
    background-color: $app-neutral-color-grey-1  !important;
    border-color: $app-neutral-color-grey-1  !important;
    color: $app-neutral-color-white  !important;
  }
}

.app-warning-outline-button {
  border-color: $app-warning-color;
  color: $app-warning-color;

  &:hover {
    background-color: $app-warning-color !important;
    border-color: $app-warning-color !important;
    color: $app-neutral-color-white !important;
  }
}

.app-danger-outline-button {
  border-color: $app-danger-color;
  color: $app-danger-color;

  &:hover {
    background-color: $app-danger-color !important;
    border-color: $app-danger-color !important;
    color: $app-neutral-color-white !important;
  }
}