$app-primary-color: #ffb400;
$app-secondary-color: #002819;
$app-tertiary-color: #ffd67f;
$app-quaternary-color: #fff8e8;
$app-quinary-color: #138365;
$app-accent-color: #e34cff;
$app-neutral-color-white: #ffffff;
$app-neutral-color-black: #000000;
$app-neutral-color-grey-1: #7f7f7f;
$app-warning-color: #f26d53;
$app-danger-color: #380025;